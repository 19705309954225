
import OPicture from "@/components/base/image/OPicture"
import Button from "@/components/base/Button"
import { mdiChevronRight } from "@mdi/js"

export default {
    name: "ArticlePreview",
    components: { OPicture, Button },
    icons: { mdiChevronRight },
    props: {
        article: {
            type: Object,
            required: true,
        },
        link: {
            type: String,
            default: undefined,
        },
    },
}
