
import { getArticles } from "@/services/v2/article.service"
import ArticlePreview from "@/components/article/ArticlePreview"
import customdomain from "@/mixins/customdomain"

export default {
    name: "ArticleListBlok",
    components: { ArticlePreview },
    mixins: [customdomain],
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        articles: [],
    }),
    async created() {
        this.articles = await this.$api.run(getArticles, {
            channel: this.blok.channel,
            type: "project",
        }).request
    },
    methods: {
        getLink(article) {
            return `${this.baseUrl}/artikel/${article.slug}`
        },
    },
}
